import React from "react";
import "./service.css";

const Service = ({ serviceNum, topText, bottomText }) => {
  return (
    <div className="service">
      <div className="service_num">{serviceNum}</div>
      <div>
        <div className="service_text_top">{topText}</div>
        <div className="service_bottom_text">{bottomText}</div>
      </div>
    </div>
  );
};

export default Service;
