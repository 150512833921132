import React from "react";

import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container main_footer">
        <div className="main_footer_left">
          <div className="main_footer_pages">
            <a href={"/"} className="main_footer_page">
              Main
            </a>
            <a href={"/products"} className="main_footer_page">
              Products
            </a>
            <a href={"/about"} className="main_footer_page">
              About
            </a>
          </div>
          <div className="main_footer_contacts">
            <div className="main_footer_page">Contacts</div>
            <div className="main_footer_page">(347)722-38-33</div>
            <div className="main_footer_page">(737)577-2727</div>
          </div>
        </div>
        <div className="main_footer_right">
          <div className="main_footer_social">
            <img
              src="/assets/images/linkedIn_footer.png"
              width={23}
              height={23}
              alt="linkedIn"
            />
            <img
              src="/assets/images/instagram_footer.png"
              width={23}
              height={23}
              alt="instagram"
              className="main_footer_icon"
            />
            <img
              src="/assets/images/twitter_footer.png"
              width={23}
              height={23}
              alt="twitter"
              className="main_footer_icon"
            />
            <img
              src="/assets/images/fb_footer.png"
              width={23}
              height={23}
              alt="facebook"
              className="main_footer_icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
