"use client";
import React from "react";

import "./navigation.css";

const Navigation = ({ onClick = Function }) => {
  return (
    <div className="container">
      <div className="navContainer">
        <img
          src="/assets/images/logo.png"
          width={195}
          height={90}
          alt="logo"
          className="logo"
        />

        <div className="nav">
          <img
            src="/assets/images/icon.png"
            width={21}
            height={20}
            alt="logo"
            onClick={onClick}
          />
          <div className="nav_pages">
            <a href={"/"} className="nav_text">
              Main
            </a>
            <a
              href="https://starexport.itemcatalog.biz/catalog"
              className="nav_text"
            >
              Products
            </a>
            <a href={"/about"} className="nav_text">
              About
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
