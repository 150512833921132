import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import Navigation from "../components/Navigation/Navigation";
import Service from "../components/Service/Service";
import Title from "../components/Title/Title.jsx";
import Product from "../components/Card/Product.jsx";

import "./App.css";
import Contacts from "../components/Contacts/Contacts.jsx";
import Divider from "../components/Divider/Divider.jsx";
import Footer from "../components/Footer/Footer.jsx";

import { client, urlFor } from "../lib/client.js";

const App = () => {
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const toggleMenu = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    (async () => {
      try {
        const productsQuery = '*[_type == "product"]';
        const product = await client.fetch(productsQuery, {
          cache: "force-cache",
        });
        const bannerQuery = '*[_type == "banner"]';
        const banner = await client.fetch(bannerQuery, {
          cache: "force-cache",
        });

        if (product && banner) {
          setProducts(product);
          setBanners(banner);
        }
      } catch (e) {
        console.lof(e);
      }
    })();
  }, []);
  return (
    <>
      {isVisible && <Modal />}
      <Navigation onClick={toggleMenu} />
      <Contacts />
      <Divider />
      <div className="container">
        <div className="dots_bottom">
          <img
            src="/assets/images/dots.png"
            width={33}
            height={20}
            alt="dotsIcon"
          />
          <img
            src="/assets/images/dots.png"
            width={33}
            height={20}
            alt="dotsIcon"
          />
        </div>

        <h3 className="main_text">
          SultanDestribution is a leading store delivery company providing high
          quality and efficient delivery services to optimize business
          logistics. Our mission is to provide fast and reliable delivery of
          goods to stores, satisfying customers' needs and providing impeccable
          service.
        </h3>

        <h2 className="main_company_name ">Sultan Distribution</h2>
        <p className="main_slogan">Your partner</p>
        <div className="main_background_top">
          <div className="main_capsule_left" />
          <div className="main_capsule_left main_capsule_right" />
        </div>
      </div>

      <div className="main_bottom">
        <div className="main_background_bottom_container">
          <div className="main_background_bottom" />
        </div>

        <img
          src="/assets/images/car.png"
          alt="car"
          width={900}
          height={360}
          className="main_bottom_image"
        />
      </div>
      {/* Service */}
      <div className="container container_center">
        <div className="main_service">
          <Service
            serviceNum={"01"}
            topText={"quality"}
            bottomText={"GUARANTED"}
          />
          <div className="main_vertical_divider" />
          <Service
            serviceNum={"02"}
            topText={"service"}
            bottomText={"FRIENDLY"}
          />
          <div className="main_vertical_divider" />
          <Service serviceNum={"03"} topText={"fast"} bottomText={"PROCESS"} />
          <div className="main_vertical_divider" />
          <Service
            serviceNum={"04"}
            topText={"highly"}
            bottomText={"AFFORDABLE"}
          />
        </div>
      </div>
      <Divider />
      {/* partners */}
      {/* <div className="main_partners_wrapper">
        <div className="container">
          <Title title={"FIND AND BOOK"} />
          <div className="main_partners_container">
            <img
              src="/assets/images/block_2.png"
              alt="partners"
              width={215}
              height={378}
              className="main_partners_image"
            />
            <div>
              <div className="main_partners_text_top">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet. It uses a
                dictionary of over 200
              </div>
              <div className="main_partners_title">Partners</div>
              <div className="main_partners_text_top main_partners_text_bot">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet. It uses a
                dictionary of over 200
              </div>
            </div>
          </div>
        </div>
        <div className="main_partners_back" />
      </div> */}
      {/* slider */}
      {/* <div>
        <div className="container main_slider_wrapper">
          <div className="arrow_left">
            <img
              src="/assets/images/arrow.png"
              alt="arrow"
              width={8}
              height={14}
              className="arrow"
            />
          </div>
          <div className="main_slider_container">
            {banners[0]?.image.length > 0 &&
              banners[0].image.map((item, index) => {
                return (
                  <img
                    key={index.toString()}
                    src={urlFor(item).url().toString()}
                    className="main_slider_image"
                    alt={index.toString()}
                  />
                );
              })}
          </div>
          <div className="arrow_left arrow_right">
            <img
              src="/assets/images/arrow.png"
              alt="arrow"
              width={8}
              height={14}
              className="arrow"
            />
          </div>
        </div>
      </div>
      <Divider /> */}

      {/* products */}
      {/* <div className="container">
        <div className="main_products">
          <div className="main_products_top">
            <div className="main_products_title">
              <Title title={"LOST"} />
              <h4>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been
              </h4>
            </div>

            <a href={"/products"} className="main_products_btn">
              All products
            </a>
          </div>

          <div className="main_products_cards">
            {products.length > 0 &&
              products.map((item, index) => {
                return <Product key={index} style={"card"} product={item} />;
              })}
          </div>
        </div>
      </div>
      <div className="container">
        <Title title={"MESSAGE TO US"} />
        <div className="main_form_container">
          <form className="main_form">
            <div className="main_form_inputs">
              <input className="main_form_input" placeholder="Name" />
              <input className="main_form_input" placeholder="Last Name" />
              <input className="main_form_input" placeholder="Phone Number" />
            </div>
            <textarea className="main_form_text" placeholder="Message" />
          </form>

          <div className="main_form_button_container">
            <button className="main_form_button">SUBMIT</button>
          </div>
        </div>
      </div>
      <Footer /> */}
    </>
  );
};

export default App;
