import React from "react";
import "./modal.css";

const Modal = ({ onPress }) => {
  return (
    <div className="modal">
      <a href="/" className="link">
        Main
      </a>
      <a href="https://starexport.itemcatalog.biz/catalog" className="link">
        Products
      </a>
      <a href="/about" className="link">
        About
      </a>
    </div>
  );
};

export default Modal;
