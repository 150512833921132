import React from "react";

import "./contacts.css";

const Contacts = () => {
  return (
    <div className="container contact_wrapper">
      {/* PhoneNumber */}
      <div className="phone_contact">
        <img src="/assets/images/dots.png" width={33} height={19} alt="dots" />
        <a href="tel:+1(737)5772727">+1(737)5772727</a>
      </div>
      {/* SOCIAL */}
      <div className="social_contact">
        <img
          src="/assets/images/linkedIn.png"
          width={20}
          height={20}
          alt="linkedIn"
        />
        <img
          src="/assets/images/instagram.png"
          width={20}
          height={20}
          alt="instagram"
        />
        <img
          src="/assets/images/twitter.png"
          width={20}
          height={20}
          alt="twitter"
        />
        <img src="/assets/images/fb.png" width={20} height={20} alt="fb" />
      </div>
    </div>
  );
};

export default Contacts;
