import { createClient } from "@sanity/client";
import ImageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "jmru85hj",
  dataset: "production",
  apiVersion: "2023-05-03",
  useCdn: false,
  token: process.env.NEXT_SANITY_TOKEN,
});

const builder = ImageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
