/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import Navigation from "../components/Navigation/Navigation";
import Contacts from "../components/Contacts/Contacts";
import Divider from "../components/Divider/Divider";
import Title from "../components/Title/Title";

import "./About.css";
import Footer from "../components/Footer/Footer";
import Modal from "../components/Modal/Modal";

const About = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleMenu = () => {
    setIsVisible((prevState) => !prevState);
  };

  return (
    <>
      {isVisible && <Modal />}
      <Navigation onClick={toggleMenu} />
      <Contacts />
      <Divider />
      <div className="container">
        <Title title={"Contacts"} />
        <div className="officeContainer">
          <h6 className="office">Office number:</h6>
          <p className="number">+17375772727</p>
        </div>

        <div className="officeContainer">
          <h6 className="office">Email:</h6>
          <p className="number">sultan.nycc@gmail.com</p>
        </div>

        <div className="officeContainer mb">
          <h6 className="office">Address:</h6>
          <p className="number">111 49th street brooklyn ny 11232</p>
        </div>

        <Title title={"Location"} />
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3058.6964878794092!2d-74.02030020680621!3d40.65062167794653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25ab011b613df%3A0x520625bafcd11898!2zMTExIDQ5dGggU3QsIEJyb29rbHluLCBOWSAxMTIzMiwg0KHQqNCQ!5e0!3m2!1sru!2s!4v1706707428871!5m2!1sru!2s"
            width="600"
            height="450"
            loading="lazy"
            referrerPolicy="no-referrer"
            className="map"
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
