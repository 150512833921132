import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation/Navigation";
import Contacts from "../components/Contacts/Contacts";
import Divider from "../components/Divider/Divider";
import Title from "../components/Title/Title";
import Product from "../components/Card/Product";

import "./Products.css";

import { client } from "../lib/client";
import Modal from "../components/Modal/Modal";

const Products = () => {
  const [products, setProducts] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const toggleMenu = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    (async () => {
      try {
        const productsQuery = '*[_type == "products"]';
        const product = await client.fetch(productsQuery, {
          cache: "force-cache",
        });
        if (products) {
          setProducts(product);
        }
      } catch (error) {
        HTMLFormControlsCollection.log(error);
      }
    })();
  }, [products]);

  return (
    <>
      {isVisible && <Modal />}
      <Navigation onClick={toggleMenu} />
      <Contacts />
      <Divider />
      <div className="container">
        <Title title={"All products"} />
        <div className="link_container">
          <a
            className="link_url"
            href="https://starexport.itemcatalog.biz/catalog"
          >
            You can see all our products by clicking on the following link
          </a>
        </div>

        <div className="products_container">
          {products.length &&
            products.map((item, index) => {
              return <Product key={index} style={"card"} product={item} />;
            })}
        </div>
      </div>
    </>
  );
};

export default Products;
