import React from "react";
import "./product.css";

import { urlFor } from "../../lib/client";

const Product = ({ style, product }) => {
  return (
    <>
      <div className={style}>
        <div className="card-container">
          <div className="title_product">{product.name}</div>
          <img
            src={urlFor(product.image[0]).url().toString()}
            className="image"
            alt={product.name.toString()}
          />
        </div>
        <div>{product?.companyName || ""}</div>
        <div className="price">{product?.price || ""}$</div>
      </div>
    </>
  );
};

export default Product;
